import media from '@activebrands/core-web/config/media';
import DiscoverDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/DiscoverDataTransformerBlock';
import { styled } from '@activebrands/core-web/libs/styletron';

const HeroWrapper = styled('section', ({ $isGrid }) => ({
    width: '100%',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
}));

const ContentWrapper = styled('section', ({ $isGrid }) => ({
    aspectRatio: 'var(--ratio-vertical-primary)',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',

    [media.min['tablet.sm']]: {
        aspectRatio: 'var(--ratio-square)',
    },

    [media.min['desktop.sm']]: {
        aspectRatio: 'var(--ratio-horizontal-primary)',
    },
}));

interface DiscoverModuleProps {
    $heroStyling?: any;
    isGrid?: boolean;
    isHero?: boolean;
}

const DiscoverModule = ({
    $heroStyling = { height: '100vh' },
    isGrid = false,
    isHero = false,
    ...rest
}: DiscoverModuleProps) => {
    if (isHero) {
        return (
            <HeroWrapper $style={$heroStyling} $isGrid={isGrid}>
                <DiscoverDataTransformerBlock isHero {...rest} />
            </HeroWrapper>
        );
    }

    return (
        <ContentWrapper $isGrid={isGrid}>
            <DiscoverDataTransformerBlock {...rest} />
        </ContentWrapper>
    );
};

export default DiscoverModule;
